import { createEnvironmentApi, Environment } from "./environment.schema";

const coreHost = "https://api.staging.qundo.net";
const envTile = "<b style='color:red;'> (STAGING: qundo.net)</b>";

export const environment: Environment = {
  api: createEnvironmentApi(coreHost),
  production: true,
  envTile: envTile,
  qrBasicUrl: "https://staging.qundo.de/apps?code=",
};
